import axios from 'axios'
import {createSearchParams} from '../../utils/main'
import { Base_url} from '../../config'


//获取权限列表
function getPermissionList(page){
    let currentPage = page || 1;
    return new Promise(resolve => {
        axios({
            method: 'get',
            url: Base_url+'/admin/getPermissionListForPage',
            params:{
                currentPage
            }
        }).then((res)=>{
            resolve(res.data)
        })
    })
}
//添加顶级权限
function addRoot(name){
    return new Promise(resolve => {
        axios({
            method: 'post',
            url: Base_url+'/admin/permission/save/root',
            params:{
                name:name
            }
        }).then((res)=>{
            resolve(res.data)
        })
    })
}
//添加权限
function savePermission(data){
    return new Promise(resolve => {
        axios({
            method: 'post',
            url: Base_url+'/admin/save_permission',
            data: createSearchParams(data)
        }).then((res)=>{
            resolve(res.data)
        })
    })
}
//更新
function updatePermission(data){
    return new Promise(resolve => {
        axios({
            method: 'post',
            url: Base_url+'/admin/update_permission',
            data: createSearchParams(data)
        }).then((res)=>{
            resolve(res.data)
        })
    })
}
//删除权限
function deletePermission(pId){
    return new Promise(resolve => {
        axios({
            method: 'post',
            url: Base_url+'/admin/deletePermission',
            params:{
                pId:pId
            }
        }).then((res)=>{
            resolve(res.data)
        })
    })
}

export {
    getPermissionList,
    addRoot,
    updatePermission,
    deletePermission,
    savePermission
}

<template>
  <div>
    <div class="e-breadcrumb">权限管理</div>
    <div class="e-card">
      <div class="e-card-body" style="padding-top:35px">
        <el-button type="primary" size="small" @click="addRootEvent">添加权限</el-button>
        <div class="container" style="width:400px;margin-top:10px;padding:10px">
          <v-tree :nodes="nodes">
            <template slot-scope="{node}">
              <a href="javascript:;" @click.stop="addEvent(node)" class="v-tree-plus"></a>
              <a href="javascript:;" @click.stop="editEvent(node)" class="v-tree-edit"></a>
              <a href="javascript:;" @click.stop="removeEvent(node)" class="v-tree-remove"></a>
            </template>
          </v-tree>
        </div>
      </div>
    </div>


    <el-dialog title="添加" width="600px" :visible.sync="addRootVisible">
      <el-form ref="form" label-width="100px">
        <el-form-item size="small" label="名称:" required>
          <el-input v-model="pName" maxlength="12" style="width: 415px" show-word-limit></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
          <el-button size="small" type="primary" @click="addRoot">确 定</el-button>
          <el-button size="small" @click="addRootVisible = false">取 消</el-button>
        </span>
    </el-dialog>
    <!-- 删除提示框 -->
    <el-dialog title="提示" :visible.sync="delVisible" width="300px" center>
      <div class="del-dialog-cnt">删除不可恢复，是否确定删除？</div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="delPermission">确 定</el-button>
        <el-button size="small" @click="delVisible = false">取 消</el-button>
      </span>
    </el-dialog>
    <Insert v-if="addVisible" :addVisible="addVisible" @closeInsert="closeInsert" @addSuccess="addSuccess" :name="name"
            :id="id"/>
    <Edit
        v-if="editVisible"
        :editVisible="editVisible"
        @closeEdit="closeEdit"
        @editSuccess="editSuccess"
        :editData="editData"
    />
  </div>
</template>

<script>
import Insert from './insert'
import Edit from './edit'
import vTree from '@/components/common/v-tree/tree'
import {dataFormat} from '../../../utils/main'
import {getPermissionList, addRoot, deletePermission} from '../../../api/sys/permissions'

export default {
    data() {
        return {
            nodes: [],
            pName: "",
            list: {},
            options: {
                data: {
                    simpleData: {
                        idKey: "id",
                        pIdKey: "parentId",
                    },
                    key: {
                        name: "permissionName",
                        children: 'children'
                    }
                }
            },
            id: '',
            name: '',
            addRootVisible: false,
            addVisible: false,
            editVisible: false,
            delVisible: false,
            editData: {}
        }
    },
    created() {
        this.searchEvent();
    },
    methods: {
        searchEvent() {
            this.requestSearch();
        },
        handleCurrentChange(page) {
            this.requestSearch(page);
        },
        async requestSearch(page) {
            let dataSet = await getPermissionList(page);
            let res = dataSet.dataSet
            let data = dataFormat(this.options, res.list)
            this.nodes = data.list
            this.list = data.map
            // this.list = res.list
        },
        //添加顶级权限
        async addRoot() {
            let res = await addRoot(this.pName)
            if (res.code == 200) {
                this.searchEvent();
                this.$message({
                    showClose: true,
                    message: res.message,
                    type: 'success'
                });
                this.addRootVisible = false
            } else {
                this.$message({
                    showClose: true,
                    message: res.message,
                    type: 'error'
                });
            }
        },
        addRootEvent() {
            this.addRootVisible = true
        },

        addEvent(node) {
            this.addVisible = true;
            this.id = node.id
            this.name = node.permissionName
        },
        editEvent(node) {
            this.editData = {
                id: node.id,
                pId: node.parentId,
                name: node.permissionName,
                pCode: node.permissionCode,
                pKey: node.permissionKey,
                rmValue: node.requestMappingValue,
                parentName: (this.list[node.parentId] ? this.list[node.parentId].permissionName : '无')
            };
            this.editVisible = true;
        },
        removeEvent(node) {
            this.pId = node.id
            this.delVisible = true
        },
        async delPermission() {
            let res = await deletePermission(this.pId)
            if (res.code == 200) {
                this.searchEvent();
                this.$message({
                    showClose: true,
                    message: res.message,
                    type: 'success'
                });
                this.delVisible = false
            } else {
                this.$message({
                    showClose: true,
                    message: res.message,
                    type: 'error'
                });
            }
        },
        addSuccess() {
            this.closeInsert();
            this.searchEvent();
        },

        closeInsert() {
            this.addVisible = false;
        },
        editSuccess() {
            this.closeEdit();
            this.searchEvent();
        },
        closeEdit() {
            this.editVisible = false;
        }
    },
    components: {
        Insert,
        Edit,
        vTree
    }
}
</script>

<style scoped>

</style>

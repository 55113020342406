<template>
  <el-dialog title="编辑" :visible.sync="editVisible" width="600px" :before-close="closeEvent">
    <el-form ref="form" label-width="100px">
      <el-form-item size="small" label="上级名称:" required>
        <p v-text="editData.parentName" style="width: 415px"></p>
      </el-form-item>
      <el-form-item size="small" label="权限代码:" required>
        <p v-text="editData.pCode" style="width: 415px"></p>
      </el-form-item>
      <el-form-item size="small" label="名称:" required>
        <el-input v-model="editData.name" style="width: 425px" maxlength="12" show-word-limit></el-input>
      </el-form-item>
      <el-form-item size="small" label="KEY:" required>
        <el-input v-model="editData.pKey" style="width: 425px" maxlength="32" show-word-limit></el-input>
      </el-form-item>
      <el-form-item size="small" label="请求接口:" required>
        <el-input v-model="editData.rmValue" style="width: 425px" maxlength="24" show-word-limit></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="updatePermission">确 定</el-button>
        <el-button size="small" @click="closeEvent">取 消</el-button>
      </span>
  </el-dialog>
</template>
<script>
import {updatePermission} from '../../../api/sys/permissions'

export default {
    data() {
        return {
            show: false,
        }
    },

    props: {
        editData: Object,
        editVisible: Boolean
    },
    methods: {
        closeEvent() {
            this.$emit('closeEdit')
        },
        async updatePermission() {
            let data = {
                id: this.editData.id,
                parentId: this.editData.pId,
                permissionName: this.editData.name,
                permissionKey: this.editData.pKey,
                requestMappingValue: this.editData.rmValue
            };
            let res = await updatePermission(data);
            if (res.code == 200) {
                this.$message({
                    showClose: true,
                    message: res.message,
                    type: 'success'
                });
                this.$emit('editSuccess')
            } else {
                this.$message({
                    showClose: true,
                    message: res.message,
                    type: 'error'
                });
            }
        }
    }
}
</script>

<style scoped>

</style>
